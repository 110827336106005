@tailwind base;
@tailwind components;
@tailwind utilities;

// IMPORT
@import 'fonts';

$orange: #ff6501;
$text: #464255;
$green: #00b074;
$red: #ea2121;
$cyan: #2d9cdb;

%hover {
	transition: all 0.2s;
	cursor: pointer;
	&:hover {
		opacity: 0.8;
	}
}

%hideSelection {
	&::selection {
		background-color: transparent;
	}
	*::selection {
		background-color: transparent;
	}
}

input[type='checkbox'] {
	position: relative;
	margin-right: 10px;
	cursor: pointer;
	&::after {
		content: '';
		height: 18px;
		width: 18px;
		background-image: url('../images/checkbox-0.svg');
		background-repeat: no-repeat;
		background-position: center center;
		background-size: contain;
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		background-color: #fff;
	}

	&:checked {
		&::after {
			background-image: url('../images/checkbox-1.svg');
		}
	}
}

/* width */
::-webkit-scrollbar {
	width: 10px;
	height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

%selectArrow {
	-moz-appearance: none !important;
	appearance: none !important;
	-webkit-appearance: none !important;
	background-image: url('../images/arrow-down.svg') !important;
	background-repeat: no-repeat !important;
	background-position: right 10px center !important;
	background-size: 13px !important;
	outline: none;
	&:focus {
		background-image: url('../images/arrow-up.svg') !important;
	}
	option {
		color: #000;
		&:first-child {
			color: #737373;
		}
	}
}

html {
	box-sizing: border-box;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin: 0;
	padding: 0;
}

ul,
ol {
	margin: 0;
	padding: 0;
	list-style: none;
}

a {
	color: inherit;
	text-decoration: none;
	img {
		width: inherit;
		height: inherit;
	}
}

button {
	cursor: pointer;
	background-color: transparent;
}

body {
	font-family: 'Montserrat';
	font-weight: 500;
	font-size: 16px;
	color: $text;
	background-color: #f3f2f7;
}

.fonts-gogle {
	font-family: 'Inter', 'Arial', sans-serif;
}

.main {
	padding: 0 40px;
	margin-left: 300px;
	@media screen and (max-width: 1536px) {
		padding: 0 28px;
	}
}

.sidebar {
	background-color: #fff;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	width: 290px;
}

.content {
	padding-bottom: 50px;
}

.head {
	display: flex;
	flex-direction: column;
	gap: 6px;
	margin-bottom: 20px;
}

.title {
	font-size: 26px;
	font-weight: 700;
	@media screen and (max-width: 1536px) {
		font-size: 22px;
		font-weight: 700;
	}
}

.greytitle {
	color: #a3a3a3;
	font-family: 'Montserrat';
	font-weight: 400;
	line-height: 125%;
	span {
		font-weight: 600;
	}
}

.minititle {
	font-size: 22px;
	font-weight: 700;
	@media screen and (max-width: 1536px) {
		font-size: 18px;
	}
}

.more {
	position: relative;
	&__trigger {
		@extend %hover;
	}

	&.active {
		.more__dropdown {
			visibility: visible;
			opacity: 1;
		}
	}

	&__dropdown {
		position: absolute;
		top: 30px;
		right: 0;
		background: #ffffff;
		border-radius: 14px;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
		padding: 12px;
		visibility: hidden;
		opacity: 0;
		transition: all 0.2s;
	}
}

input,
textarea,
select {
	outline: none;
}

.btn {
	padding: 12px 32px;
	border: none;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
	border-radius: 12px;
	color: #fff;
	@extend %hover;
	&_green {
		background: $green;
	}
}

.lds-facebook {
	display: inline-block;
	width: 80px;
	height: 80px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	margin: 100px 0;
	z-index: 99999999;
}

.lds-facebook div {
	display: inline-block;
	position: absolute;
	left: 8px;
	width: 16px;
	background: $orange;
	animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
	left: 8px;
	animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
	left: 32px;
	animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
	left: 56px;
	animation-delay: 0;
}
@keyframes lds-facebook {
	0% {
		top: 8px;
		height: 64px;
	}
	50%,
	100% {
		top: 24px;
		height: 32px;
	}
}

.header,
.content {
	max-width: 1920px;
	margin: auto;
}

.input-danger {
	outline: 1px solid tomato;
}

.red {
	color: tomato;
}
