/* MONTSERRAT - 400 */
@font-face {
  font-display: swap; 
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/montserrat-v25-cyrillic_latin-regular.eot'); 
  src: url('../fonts/montserrat-v25-cyrillic_latin-regular.eot?#iefix') format('embedded-opentype'),
       url('../fonts/montserrat-v25-cyrillic_latin-regular.woff2') format('woff2'),
       url('../fonts/montserrat-v25-cyrillic_latin-regular.woff') format('woff'), 
       url('../fonts/montserrat-v25-cyrillic_latin-regular.ttf') format('truetype'), 
       url('../fonts/montserrat-v25-cyrillic_latin-regular.svg#Montserrat') format('svg');
}

/* MONTSERRAT - 500 */
@font-face {
  font-display: swap; 
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/montserrat-v25-cyrillic_latin-500.eot'); 
  src: url('../fonts/montserrat-v25-cyrillic_latin-500.eot?#iefix') format('embedded-opentype'),
       url('../fonts/montserrat-v25-cyrillic_latin-500.woff2') format('woff2'),
       url('../fonts/montserrat-v25-cyrillic_latin-500.woff') format('woff'), 
       url('../fonts/montserrat-v25-cyrillic_latin-500.ttf') format('truetype'), 
       url('../fonts/montserrat-v25-cyrillic_latin-500.svg#Montserrat') format('svg');
}

/* MONTSERRAT - 600 */
@font-face {
  font-display: swap; 
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/montserrat-v25-cyrillic_latin-600.eot'); 
  src: url('../fonts/montserrat-v25-cyrillic_latin-600.eot?#iefix') format('embedded-opentype'),
       url('../fonts/montserrat-v25-cyrillic_latin-600.woff2') format('woff2'),
       url('../fonts/montserrat-v25-cyrillic_latin-600.woff') format('woff'), 
       url('../fonts/montserrat-v25-cyrillic_latin-600.ttf') format('truetype'), 
       url('../fonts/montserrat-v25-cyrillic_latin-600.svg#Montserrat') format('svg');
}

/* MONTSERRAT - 700 */
@font-face {
  font-display: swap; 
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/montserrat-v25-cyrillic_latin-700.eot'); 
  src: url('../fonts/montserrat-v25-cyrillic_latin-700.eot?#iefix') format('embedded-opentype'),
       url('../fonts/montserrat-v25-cyrillic_latin-700.woff2') format('woff2'),
       url('../fonts/montserrat-v25-cyrillic_latin-700.woff') format('woff'), 
       url('../fonts/montserrat-v25-cyrillic_latin-700.ttf') format('truetype'), 
       url('../fonts/montserrat-v25-cyrillic_latin-700.svg#Montserrat') format('svg');
}

/* INTER 400 */
@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/inter-v12-cyrillic_latin-regular.eot');
  src: url('../fonts/inter-v12-cyrillic_latin-regular.eot?#iefix') format('embedded-opentype'),
       url('../fonts/inter-v12-cyrillic_latin-regular.woff2') format('woff2'),
       url('../fonts/inter-v12-cyrillic_latin-regular.woff') format('woff'), 
       url('../fonts/inter-v12-cyrillic_latin-regular.ttf') format('truetype'), 
       url('../fonts/inter-v12-cyrillic_latin-regular.svg#Inter') format('svg'); 
}

/* INTER - 500 */
@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/inter-v12-cyrillic_latin-500.eot');
  src: url('../fonts/inter-v12-cyrillic_latin-500.eot?#iefix') format('embedded-opentype'),
       url('../fonts/inter-v12-cyrillic_latin-500.woff2') format('woff2'),
       url('../fonts/inter-v12-cyrillic_latin-500.woff') format('woff'), 
       url('../fonts/inter-v12-cyrillic_latin-500.ttf') format('truetype'), 
       url('../fonts/inter-v12-cyrillic_latin-500.svg#Inter') format('svg'); 
}

/* INTER - 600 */
@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/inter-v12-cyrillic_latin-600.eot');
  src: url('../fonts/inter-v12-cyrillic_latin-600.eot?#iefix') format('embedded-opentype'),
       url('../fonts/inter-v12-cyrillic_latin-600.woff2') format('woff2'),
       url('../fonts/inter-v12-cyrillic_latin-600.woff') format('woff'), 
       url('../fonts/inter-v12-cyrillic_latin-600.ttf') format('truetype'), 
       url('../fonts/inter-v12-cyrillic_latin-600.svg#Inter') format('svg'); 
}

/* INTER - 700 */
@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/inter-v12-cyrillic_latin-700.eot');
  src: url('../fonts/inter-v12-cyrillic_latin-700.eot?#iefix') format('embedded-opentype'),
       url('../fonts/inter-v12-cyrillic_latin-700.woff2') format('woff2'),
       url('../fonts/inter-v12-cyrillic_latin-700.woff') format('woff'), 
       url('../fonts/inter-v12-cyrillic_latin-700.ttf') format('truetype'), 
       url('../fonts/inter-v12-cyrillic_latin-700.svg#Inter') format('svg'); 
}