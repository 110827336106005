/* Styling the scrollbar */
.wrapper::-webkit-scrollbar {
	width: 5px; /* Width of the scrollbar */
}

.wrapper::-webkit-scrollbar-thumb {
	background-color: #7684b6; /* Color of the scrollbar thumb */
	border-radius: 4px; /* Rounded corners of the thumb */
}

.wrapper::-webkit-scrollbar-track {
	margin-top: 10px;
	background-color: transparent; /* Color of the scrollbar track */
}
